<template>
  <div class="box" style="height: calc(100vh - 110px);">
    <div class=" ">
      <div class="flex py-3 mb-4">
        <i :class="`pi pi-angle-left my-auto cursor-pointer mx-2 my-auto`" style="font-size: 1.4rem" @click="router.back()"></i>
          <!-- <UserIcon class="text-theme-22 my-auto" /> -->
        <i :class="`pi pi-align-right mx-2 my-auto`" style="font-size: 1rem" ></i>
        <b class="my-auto">HISTORIAL</b>
<!--        <b class="mx-auto text-xl">{{ carpetas.nombre }}</b>-->
      </div>
      <DataTable :value="listArchivos" class="p-datatable-sm" :rowHover="true"  showGridlines responsiveLayout="scroll">
          <!-- <Column header="" style="min-width:50px">
              <template #body="{data}">
                  <img
                  v-if="data.EstDireccionamiento == 1"
                  src="/img/icon_circle_refresh_blue.svg"
                  v-tooltip="'Programar Mipres'"
                  class="cursor-pointer m-1" alt=""
                  @click="accion(data, 'Programacion')"
                  >
              </template>
          </Column> -->
          <Column header="Nombre" style="min-width:130px">
            <template #body>
              <p style="overflow-wrap: break-word">{{ carpetas.nombre }}</p>
            </template>
          </Column>
          <Column header="Version" style="min-width:180px">
              <template #body="{data}">
              <p style="overflow-wrap: break-word">{{data.version}}</p>
              </template>
          </Column>
          <!-- <Column header="NoPrescripción" style="min-width:180px">
              <template #body="{data}">
              <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
          </template>
          </Column> -->
          <Column header="Fecha Actualización" style="min-width:180px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.updatedAt.substring(0, 10)}}</p>
            </template>
          </Column>
          <Column header="Acciones" style="min-width:180px">
            <template #body="{data}">
              <div :key="key" class="flex p-2 bg-item rounded-lg my-1">
                <i
                :class="`pi pi-${ data.tipo === 'pdf' || data.tipo === 'txt' ? 'file-pdf text-red-400': 'file-excel text-green-400'} my-auto mx-2 cursor-pointer`"
                style="font-size: 1rem"
                @click="displayViewDoc = true, getArchivoPath(data, carpetas)"
                ></i>
              </div>
          </template>
          </Column>
          <!-- <Column field="proceso" header="Proceso" style="min-width:130px"></Column> -->
          <!-- <Column field="responsable" header="Responsable" style="min-width:130px"></Column> -->
          <!-- <Column field="solicitante" header="Solicitante de Actualización" style="min-width:80px"></Column> -->
          <!-- <Column field="NoIDPaciente" header="NoIDPaciente" style="min-width:130px"></Column> -->
      </DataTable>
      <!-- {{ carpetas }} -->

    </div>
    <!-- BEGIN: nuevo doc -->
    <Dialog v-model:visible="displayAddDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
      <template v-slot:header>
            <div class="w-full">
                <div class="flex justify-center">
                  <FilePlusIcon class="block mr-2 h-5" />
                  <b>Agregar Documento</b>
                </div>
            </div>
        </template>
        <div class="mb-5">
            <label class="mb-2">Nombre del documento.value</label>
            <InputText v-model="newDocumento.nombre" type="text" class="rounded-md w-full" />
        </div>
        <div class="">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
        <template #footer>
            <Button label="Si" icon="pi pi-check" autofocus @click="crearDocumento()" />
            <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayPrograma = false" />
        </template>
    </Dialog>
    <!-- END: nuevo doc -->
    <!-- BEGIN: ver doc -->
    <Dialog v-model:visible="displayViewDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '60vw', height: '80vh'}">
      <div class="mb-5 flex flex-col " style="height:70vh">
        <!-- <embed v-if="filePath" :src="`data:application/pdf;base64,${filePath}#zoom=80&bookmarks=1`" style="width:100%;height: 100%;"> -->
          <embed v-if="tipoView === 'pdf'" :src="filePath" style="width:100%;height: 100%;">
          <embed v-if="tipoView === 'txt'" :src="`data:application/pdf;base64,${filePath}#zoom=80&bookmarks=1`" style="width:100%;height: 100%;">
      </div>
    </Dialog>
    <!-- END: ver doc -->
  </div>
</template>
<script>

import { onMounted, ref, computed, onBeforeMount } from 'vue'
import CarpetasServices from './services/get.service'
import PutService from './services/put.service'
import PostService from './services/post.service'
import { useRoute, useRouter } from 'vue-router'
import FilePond from './components/filePound.vue'
import FilePondStore from './filepond.store'
import store from './store'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
export default {
  name: 'CalidadHistorial',
  components: {
    FilePond
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const _Get = ref(new CarpetasServices())
    const _Post = ref(new PostService())
    const _Put = ref(new PutService())
    const _ModalSolictudesService = ref(new ModalSolicitudesService())
    const listArchivos = ref([])
    const adjuntos = ref([])
    const title = ref('')
    const filePath = ref('')
    const carpetas = ref([])
    const proceso = ref({})
    const addCarpeta = ref(false)
    const newCarpeta = ref('')
    const editarFolder = ref(0)
    const addDocumento = ref(false)
    const newDocumento = ref({})
    const displayAddDoc = ref(false)
    const displayViewDoc = ref(false)
    const tipoView = ref('pdf')
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )

    // const getProceso = () => {
    //   _Get.value.getProceso(route.params.id).then(({ data }) => {
    //     proceso.value = data
    //   }).catch(err => {
    //   console.log(err.message)
    //   })
    // }

    const listarCarpetas = () => {
      _Get.value.getMapaProceso(route.params.id).then(({ data }) => {
        // data.map(m => {
        //   m.documentos = []
        //   m.dropdown = false
        // })
          carpetas.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }
    const desplegarDocumentos = (event) => {
      _Get.value.getdocumentos({ carpetaId: event.id }).then(({ data }) => {
            const itemsTmp = []
            for (const row of data) {
                const datos = {
                    nombre: row.nombre,
                    id: row.id,
                    posicion: row.posicion
                }
                itemsTmp.push(datos)
            }
            // carpetaActived.value = carpetaActived.value === event.id ? 0 : event.id
            // if (itemsTmp.length) documentos.value = itemsTmp
            event.actived = event.actived ? 0 : true
            event.documentos = itemsTmp
        }).catch(err => {
        console.log(err.message)
        })
      }
    const actualizarCarpeta = (proId, folder) => {
        var { id, nombre } = folder
        _Put.value.putCarpeta({ id, nombre, padre: proId }).then(({ data }) => {
            editarFolder.value = 0
        }).catch(err => {
        console.log(err.message)
        })
    }
    const getArchivos = () => {
      _Get.value.getArchivo(route.params.id).then(({ data }) => {
        // console.log('listArchivos----', data)
            listArchivos.value = data
        }).catch(err => {
          console.log(err.message)
        })
    }

    const getArchivoPath = (file, doc) => {
      _Get.value.getArchivoPath(doc.id).then(({ data }) => {
          file.path = data
          viewPdf(file, doc)
        }).catch(err => {
          console.log(err.message)
        })
    }
    const viewPdf = (file, doc) => {
      // console.log('el path-view---', file)
      // console.log('el path-view---', doc)
      tipoView.value = file.tipo
      var arraynombre = doc.nombre.split(' ')
      var dataHtml = {
          path: file.path,
          tipo: file.tipo,
          cod: file.cod ? file.cod : arraynombre.shift(),
          nombreArchivo: file.nombre ? file.nombre : arraynombre.join(' '),
          version: file.version,
          createdAt: file.createdAt.substring(0, 10),
          updatedAt: file.updatedAt.substring(0, 10)
        }
        if (file.tipo === 'pdf') {
            const url = _ModalSolictudesService.value.getUri({ path: file.path, tipo: file.tipo })
            // console.log('url------', url)
            if (file.tipo === 'pdf' || file.tipo === 'txt') {
                    filePath.value = url.base ? url.base : url
                store.dispatch('updateTipoView', 'pdf')
                } else {
                window.open(url, '_blank')
                }
        } else {
            _ModalSolictudesService.value.getHtml(dataHtml).then(({ data }) => {
                // console.log('viewPdf------', data)
                if (file.tipo === 'txt') {
                //   filePath.value = data
                    filePath.value = data.base ? data.base : data
                //   props.tipoView.value = 'pdf'
                    store.dispatch('updateTipoView', 'pdf')
                } else {
                  window.open(data, '_blank')
                }
            }).catch(err => {
              console.log(err.message)
            })
        }
        // _ModalSolictudesService.value.getHtml(dataHtml).then(({ data }) => {
        //     // console.log('viewPdf------', data)
        //     if (file.tipo === 'pdf' || file.tipo === 'txt') {
        //     //   filePath.value = data
        //         filePath.value = data.base
        //     //   props.tipoView.value = 'pdf'
        //     store.dispatch('updateTipoView', 'pdf')
        //     } else {
        //       window.open(data, '_blank')
        //     }
        // }).catch(err => {
        //   console.log(err.message)
        // })
    }
    const modalAddDoc = (folder) => {
      displayAddDoc.value = true
      newDocumento.value.carpetaId = folder.id
      newDocumento.value.posicion = folder.documentos.length ? (Math.max(...folder.documentos.map(o => o.posicion)) + 1) : 1
      adjuntos.value.push({
        urlPath: adjunto.value.urlPath,
        fileName: title.value
      })
    }
    const crearDocumento = () => {
        _Post.value.crearDocumento(newDocumento.value).then(({ data }) => {
            desplegarDocumentos({ id: newDocumento.value.carpetaId })
            addDocumento.value = false
            newDocumento.value = {}
            displayAddDoc.value = false
        }).catch(err => {
        console.log(err.message)
        })
    }
    onBeforeMount(() => {
      getArchivos()
    })

    onMounted(() => {
      // var myEmbed = document.getElementById('myEmbed')
      // myEmbed.setAttribute('controls', false)
      // getProceso()
      listarCarpetas()
      // getArchivos()
    })
    return {
      listArchivos,
      filePath,
      router,
      route,
      carpetas,
      proceso,
      addCarpeta,
      newCarpeta,
      editarFolder,
      addDocumento,
      newDocumento,
      displayAddDoc,
      displayViewDoc,
      tipoView,
      getArchivos,
      getArchivoPath,
      modalAddDoc,
      desplegarDocumentos,
      actualizarCarpeta,
      crearDocumento
    }
  }
}
</script>
<style lang="scss">
.bg-grad{
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}
.w-proceso{
  width: 30%;
}
</style>
