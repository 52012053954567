import { createStore } from 'vuex'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'

const _ModalSolictudesService = new ModalSolicitudesService()

const storeIncomes = {
  state: {
    TipoProcesos: [],
    TipoView: 'folders',
    token: null,
    difference: 0,
    respuestaId: null,
    solicitudes: {
      count: 0,
      rows: []
    },
    solicitud: {
      ruta: ''
    },
    infoPublicar: {
      areas: []
    },
    modalPublicar: false
  },
  mutations: {
    setTipoProcesos (state, payload) {
      payload.map(m => {
        m.procesos = []
      })
      state.TipoProcesos = payload
    },
    setTipoView (state, payload) {
      state.TipoView = payload
    },
    setRespuestaId (state, payload) {
      state.respuestaId = payload
    },
    setSolicitudes (state, payload) {
      state.solicitudes = payload
    },
    setSolicitud (state, payload) {
      state.solicitud = payload
    },
    setInfoPublicar (state, payload) {
      state.infoPublicar = payload
    },
    setModalPublicar (state, payload) {
      state.modalPublicar = payload
    }
  },
  actions: {
    updateTiposProcesos ({ commit }, payload) {
      commit('setTipoProcesos', payload)
    },
    updateTipoView ({ commit }, payload) {
      commit('setTipoView', payload)
    },
    updateRespuestaId ({ commit }, payload) {
      commit('setRespuestaId', payload)
    },
    getSolicitudes ({ commit }, params) {
      _ModalSolictudesService.getSolicitudes(params).then(({ data }) => {
        commit('setSolicitudes', data)
      })
    },
    getSolicitud ({ commit }, id) {
      return _ModalSolictudesService.findSolicitud(id).then(async ({ data: dataFinal }) => {
        dataFinal.nombreArchivo = dataFinal.ruta.split('>')
        dataFinal.nombreArchivo = dataFinal.nombreArchivo[dataFinal.nombreArchivo.length - 1]
        return _ModalSolictudesService.getHtml({
          path: dataFinal.path,
          tipo: 'txt',
          nombreArchivo: dataFinal.nombreArchivo,
          version: dataFinal.version,
          cod: '',
          createdAt: dataFinal.createdAt.substr(0, 10),
          updatedAt: dataFinal.updatedAt.substr(0, 10)
        }).then(({ data }) => {
          dataFinal.pathBase64 = data.base
          return commit('setSolicitud', dataFinal)
        })
      })
    },
    async actionModalPublicar ({ commit, state }, payload) {
      await commit('setInfoPublicar', payload)
      await commit('setModalPublicar', !state.modalPublicar)
    }
  },
  getters: {
    getTipoProcesos: state => state.TipoProcesos,
    getTipoView: state => state.TipoView,
    getRespuestaId: state => state.respuestaId,
    getSolicitudes: state => state.solicitudes,
    getSolicitud: state => state.solicitud,
    getInfoPublicar: state => state.infoPublicar,
    getModalPublicar: state => state.modalPublicar
  }
}
const store = createStore(storeIncomes)

export default {
  namespaced: true,
  ...store
}
