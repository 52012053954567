import http from '../../../../../../libs/http'
const url = process.env.VUE_APP_MICROSERVICE_API_CALIDAD

export default class ModalSolicitudesService {
  getTiposProcesos (query) {
    return http.get(`${url}/mapa-procesos`, {
      params: query,
      headers: {
        loading: true
      }
    })
  }

  getProcesos (params) {
    return http.get(`${url}/mapa-procesos/`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getCarpetas (params) {
    return http.get(`${url}/mapa-procesos/`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getDocumentos (params) {
    return http.get(`${url}/mapa-procesos/`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearSolicitud (data) {
    return http.post(`${url}/solicitudes`, data, {
      headers: {
        loading: true
      }
    })
  }

  getSolicitudes (params) {
    return http.get(`${url}/solicitudes/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  findSolicitud (id) {
    return http.get(`${url}/solicitudes/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getUri (params) {
    const uri = `${url}/archivos/visualizar`
    return http.getUri({ url: uri, params })
  }

  getHtml (data) {
    return http.get(`${url}/archivos/visualizar`, {
      params: data,
      headers: {
        loading: true
      }
    })
  }

  obtenerHtml (documentoId) {
    return http.get(`${url}/archivos/obtener-html/${documentoId}`, {
      headers: {
        loading: true
      }
    })
  }

  obtenerHtmlBySolicitud (solicitudId) {
    return http.get(`${url}/solicitudes/obtener-html/${solicitudId}`, {
      headers: {
        loading: true
      }
    })
  }

  getCreadoPorList () {
    return http.get(`${url}/solicitudes/list-user`, {
      headers: {
        loading: true
      }
    })
  }

  getAreasList () {
    return http.get(`${url}/solicitudes/get-areas`, {
      headers: {
        loading: true
      }
    })
  }

  responderSolicitud (data) {
    return http.put(`${url}/solicitudes/responder`, data, {
      headers: {
        loading: true
      }
    })
  }

  publicarSolicitud (data) {
    return http.put(`${url}/solicitudes/publicar`, data, {
      headers: {
        loading: true
      }
    })
  }

  generarReporte (filters) {
    return http.getUri({ url: `${url}/solicitudes/reporte`, params: filters })
  }

  generarListado () {
    return http.getUri({ url: `${url}/solicitudes/listado-maestro` })
  }

  getTiposSolicitud () {
    return http.get(`${url}/solicitudes/tipos`, {
      headers: {
        loading: true
      }
    })
  }

  getEstados () {
    return http.get(`${url}/solicitudes/estados`, {
      headers: {
        loading: true
      }
    })
  }

  getEncargados (params) {
    return http.get(`${url}/solicitudes/list-encargados`, {
      params: {
        ...params
      },
      headers: {
        loading: true
      }
    })
  }

  corregirSolicitud (id, data) {
    return http.put(`${url}/solicitudes/corregir/${id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  anularSolicitud (id) {
    return http.put(`${url}/solicitudes/anular/${id}`, {}, {
      headers: {
        loading: true
      }
    })
  }
}
